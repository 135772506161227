<template>
  <div>
    <div class="body-2 mb-1">
      <v-chip small color="pink" label text-color="white">
        <b>Live</b>
      </v-chip>
      <v-badge
          color="yellow lighten-3"
          offset-x="7"
          class=" text-h6"
        >
            <template v-slot:badge>
                  <span class="black--text">{{ totalrecords }}</span>
            </template>
        </v-badge>
    </div>
    <div class="pt-2" style="height:440px; overflow:auto;">
            <v-card
            :disabled="disabled"
            v-for="(item, index) in items"
            :key="index"
            class="mx-auto mb-2"
            :color="item.color"
            >
            <v-card-text class="pa-2 grey--text text--darken-4">
                <div class="mydatanewline" v-html="item.detail"></div>
            </v-card-text>
            <v-card-actions class="pt-1 pb-1 caption grey--text text--darken-2 yellow lighten-4">
              <v-chip x-small label color="green" class="pl-1 pr-1" dark><b>{{item.id}}</b></v-chip>
                <v-icon size="18" class="mr-1 ml-1" @click="doDelete(item.id)"
                >mdi-trash-can-outline</v-icon
                >
                {{ item.createdAt | moment("DD-MM-YYYY HH:mm") }}
                <v-icon size="18" class="mr-1 ml-1">mdi-face-outline</v-icon>
                {{ createdBy(item.ref_user_id) }}
                <v-spacer></v-spacer>
                <v-icon class="mr-2" @click="addToBill(item)">mdi-plus</v-icon>
            </v-card-actions>
            </v-card>
    </div>

    <v-snackbar
      v-model="snackbar.active"
      top
      left
      app
      :color="snackbar.color"
      dark
      :timeout="1000"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn text v-bind="attrs" @click="snackbar.active = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import api, { LIVEORDERSTATUS } from "@/services/api";
import _ from "lodash";

export default {
  name: "LiveOrderCard",
  data() {
    return {
      snackbar: { active: false, text: "", color: "" },
      items: [],
      users: [],
      disabled: true,
    };
  },
  methods: {
    async doDelete(id) {
        if (confirm('[ ' + id + ' ] คุณต้องการลบรายการนี้ ใช่หรือไม่')) {
        this.disabled = true;
        let result = await api.deleteLiveOrder(id);
        if (result.data.result == "ok") {
          this.snackbar = {
            active: true,
            text: "ลบ Live oder สำเร็จ",
            color: "pink darken-2",
          };
          this.getData();
        } else {
          this.snackbar = {
            color: "red darken-2",
            active: true,
            text: "กรุณาลองใหม่อีกครั้ง",
          };
        }
        this.disabled = false;
      }
    },
    async getData() {
      this.disabled = true;
      let result = await api.getLiveOrdersASC();
      let data = [];
      _.forEach(result.data, (r, i) => {
        data.push({
          id: r.id,
          detail: r.detail,
          ref_user_id: r.ref_user_id,
          createdAt: r.createdAt,
          color: 'yellow lighten-5'
        })
      })
      // console.log(result.data)
      this.items = data;
      this.disabled = false;
    },
    async getUsers() {
      let result = await api.getUsers(1, 100, "");
      this.users = result.data.rows;
    },
    createdBy(user_id) {
      let user = _.find(this.users, { id: user_id });
      return user ? user.username : "";
    },
    addToBill(item) {
      item.color = 'yellow lighten-2';
      this.$emit("selected", item);
    }
  },
  computed: {
    totalrecords() {
      return this.items.length;
    },
  },
  mounted() {
    this.getData();
    this.getUsers();
  },
  beforeDestroy: function() {
    delete this.items;
    delete this.users;
  }
}
</script>

<style scoped>
.mydatanewline {
  white-space: pre-wrap;
  word-wrap: break-word;
}
</style>
