<template>
  <v-container fluid grey lighten-3>
    <v-row class="pl-5 pr-5">
      <v-col md="4">
          <!-- Live order -->
            <LiveOrderCard @selected="orderlive_selected" ref="liveordercardname" />
          <!-- End live order -->
      </v-col>
      <v-col md="8" cols="12">
        <v-row>
          <v-col>
            <h3 class="font-weight-normal subtitle-2"><v-icon size="18">post_add</v-icon> สร้างใบสั่งซื้อ</h3>
          </v-col>
        </v-row>

        <!-- form address -->
        <FormCard
          :items="itemSelectedList"
          :products="products"
          :liveorderselected="liveorderselecteditem"
          @completed_order="orderComplete"
          @selected_branch="selected_branch"
          @clear-items="clearItemSelected"
          @valid-form="isValidFormCard"
          @change-amount="changeAmount"
          @detect-item="addToCartOnce"
          @clear-liveorder="reloadLiveCard"
          ref="formcardaddress"
        />
      </v-col>
    </v-row>

    <v-container fluid>
      <v-row class="pl-2 pr-2">
        <!-- SELECTED products -->
        <!-- <v-col cols="12"> -->
          <!-- <v-row>
            <v-col md="6">
              <v-select
                :items="categories"
                item-text="name"
                item-value="id"
                :rules="[(v) => !!v || 'กรุณาเลือก']"
                label="กรุณาเลือก หมวดหมู่"
                @change="selectCategory"
                :loading="isLoading"
                v-model="select_cat"
              ></v-select>
            </v-col>
          </v-row> -->

            <!-- 
              <v-row>
              <v-col
              md="1"
              sm="6"
              cols="6"
              class="pa-0"
              v-for="(product, index) in products"
              :key="index"
            >
              <ProductCard
                :item="product"
                @selected-item="addToCart"
                :key="index"
              />
            </v-col> 
            </v-vrow> -->
        <!-- </v-col>  -->

          <v-dialog v-model="dialogNewProduct" transition="dialog-bottom-transition">
            <v-card class="pt-5 pb-5">
              <v-card-text>
                <v-select
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  :rules="[(v) => !!v || 'กรุณาเลือก']"
                  label="กรุณาเลือก หมวดหมู่"
                  @change="selectCategory"
                  :loading="isLoading"
                  v-model="select_cat"
                ></v-select>

                <v-row>
                  <v-col
                    md="1"
                    sm="6"
                    cols="6"
                    class="pa-1"
                    v-for="(product, index) in products"
                    :key="index"
                  >
                    <ProductCard
                      :item="product"
                      @selected-item="addToCart"
                      :key="index"
                    />
                  </v-col>
                </v-row>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="red darken-1"
                  text
                  @click="dialogNewProduct = false"
                  >ปิด</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-dialog
            v-model="dialogKaBooking"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
          >
            <v-card>
               <v-toolbar
                  light
                  color="yellow darken-1"
                  height="40"
                >
                  <v-btn
                    icon
                    light
                    @click="dialogKaBooking = false"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                  <v-toolbar-title class="text-body-1">KA-Booking</v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-toolbar-items>
                    <v-btn
                      light
                      text
                      @click="dialogKaBooking = false"
                    >
                      ปิด
                    </v-btn>
                  </v-toolbar-items>
                </v-toolbar>
                <KABookingSheet></KABookingSheet>
            </v-card>
          </v-dialog>


        <v-col cols="12" class="pl-2 pr-2 pt-10 mt-sm-0">
          <div class="d-flex justify-md-center justify-left mb-5">
            <v-btn color="info" small dark @click.stop="dialogNewProduct = true"
              ><v-icon class="mr-1" size="18" left>add_circle</v-icon>
              รายการสินค้า</v-btn
            >
              <v-divider class="ml-5 mr-3" vertical></v-divider>

              <v-btn class="ml-2" color="yellow darken-1" small light @click.stop="dialogKaBooking = true"
              ><v-icon class="mr-1" size="18" left>add_circle</v-icon>
              KA-Booking</v-btn
            >
          </div>

          <v-row>
            <template v-if="itemSelectedList.length > 0">
              <v-col
                md="4"
                class="pa-1"
                v-for="(item, index) in itemSelectedList"
                :key="index"
                ref="rowCard"
              >
                <v-card v-if="item.name" outlined>
                  <v-list-item class="pl-0 pr-0">
                    <v-img
                      :src="item.image | imageUrl"
                      aspect-ratio="1"
                      max-height="75"
                      max-width="75"
                      class="ma-1"
                    ></v-img>

                    <v-list-item-content>
                      <v-list-item-title class="subtitle-2 mb-1"
                        >
                        {{ item.name }}</v-list-item-title
                      >
                      <v-list-item-subtitle><v-icon small color="blue darken-2"
                          >check_circle</v-icon
                        > {{
                        item.subname
                      }}</v-list-item-subtitle>
                    </v-list-item-content>
                    <v-card-actions class="pa-0">
                      <v-btn class="ml-2" icon @click="minusItem(item)" small
                        ><v-icon>remove</v-icon></v-btn
                      >

                      <v-text-field
                        label="จำนวน"
                        v-model="item.qty"
                        type="number"
                        dense
                        style="width:50px;"
                        class="centered-input"
                      ></v-text-field>

                      <v-btn class="mr-2" icon @click="plusItem(item)" small
                        ><v-icon>add</v-icon></v-btn
                      >
                    </v-card-actions>
                  </v-list-item>
                </v-card>
              </v-col>
              <v-col cols="12" align="right" v-if="enable_submitbtn" class="grey lighten-4 mt-2">
                <div class="d-flex justify-center">
                  <!-- ส่งไปที่ formcard component -->
                  <div class="pr-5 subtitle-2 grey--text text--darken-2">
                    <span>ผู้รับ: <b>{{ customer_name }}</b></span
                    > | 
                    <span
                      >รวม <b>{{ show_order_amount }}</b> บ.</span
                    >
                  </div>
                  <!-- <div>
                    <v-btn
                      :disabled="!enable_submitbtn"
                      color="success"
                      @click="submitFormCard"
                      x-large
                      ><v-icon class="mr-1">check_circle</v-icon> บันทึก</v-btn
                    >
                  </div> -->
                </div>
              </v-col>
            </template>
            <template v-else>
              <v-col md="12" class="pl-0 pr-0">
                <v-alert
                  border="right"
                  colored-border
                  type="error"
                  elevation="2"
                  dense
                >
                  เลือกสินค้า
                </v-alert>
              </v-col>
            </template>
          </v-row>
        </v-col>

        <v-dialog v-model="confirmDeleteDlg" max-width="290">
          <v-card>
            <v-card-title class="headline">Confirm Delete</v-card-title>
            <v-card-text class="body">
              ต้องการลบรายการนี้ใช่หรือไม่?
              <h3>{{ itemSelectedIndex.name }}</h3>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="confirmDeleteDlg = false"> cancel </v-btn>
              <v-btn color="error" text @click="confirmDeleteItem"
                >Confirm</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-dialog>
        <!-- end selected -->
      </v-row>
    </v-container>

    <v-row>
      <v-col cols="12">
        <h3 class="ml-3 mb-2 subtitle-1">
          <v-icon>cloud_queue</v-icon> เตรียมจัดส่ง
        </h3>
        <OrderCart :hasOrder="reloadOrder" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LiveOrderCard from "@/components/billing/LiveOrderCard";
import FormCard from "@/components/billing/FormCard";
import ProductCard from "@/components/billing/ProductCard";
import KABookingSheet from "@/components/KABookingSheet";
import OrderCart from "@/components/billing/OrderCart";
import api from "@/services/api";
import _ from "lodash";

export default {
  name: "Billing",
  components: {
    ProductCard, // display product list
    OrderCart, // display orders ที่เพิ่มสร้างขึ้น
    FormCard, // form
    LiveOrderCard,
    KABookingSheet
  },
  data() {
    return {
      dialogKaBooking: false,
      dialogNewProduct: false,
      show_order_amount: "",
      customer_name: "",
      select_cat: "",
      reloadOrder: "",
      products: [],
      categories: [],
      search: "",
      isLoading: false,
      itemSelectedList: [], //รายการสินค้าที่ add ลง cart
      item: "",
      confirmDeleteDlg: false,
      itemSelectedIndex: {}, //สำหรับลบ
      enable_submitbtn: false,
      liveorderselecteditem: '',
    };
  },
  async mounted() {
    let result = await api.getCategoryActive();
    this.categories = result.data;
    // this.loadItems();
  },
  methods: {
    reloadLiveCard() {
      this.liveorderselecteditem = '';
      this.$refs.liveordercardname.getData();
    },
    orderlive_selected(item) {
      this.liveorderselecteditem = item;
    },
    selected_branch(val) {
      let branch_selected = val.toLowerCase(); // from FormCard component
      let c = _.find(this.categories, (cat) => {
        let cname = cat.name.toLowerCase();
        let bname = branch_selected.split(" ");
        return cname.search(bname[0]) > -1 ? true : false;
      });
      if (c) {
        this.select_cat = c.id;
        this.selectCategory(c.id);
      }
    },
    async selectCategory(id) {
      this.isLoading = true;
      let result = await api.getProductByCategoryId(id);
      this.products = result.data;
      this.isLoading = false;
    },
    orderComplete(info) {
      // from FormCard ที่ทำการ emit  event ออกมาชื่อ branch_selected พร้อมส่งค่า id ออกมา
      if (info) {
        this.reloadOrder = info;
      }
      this.itemSelectedList = [];
    },
    clearItemSelected() {
      this.itemSelectedList = [];
    },
    addToCart(item) {
      this.item = { item };
    },
    addToCartOnce(items) {
      _.forEach(items, (item) => {
          _.delay((it) => {
           this.item = { item:it.prd, initQty:it.qty };
          }, 800, item)
      })
    },
    plusItem(item) {
      let i = _.findIndex(this.itemSelectedList, { id: item.id });
      if (i >= 0) {
        let qty = Number.parseInt(this.itemSelectedList[i].qty);
        this.itemSelectedList[i].qty = qty + 1;
      }
    },
    minusItem(item) {
      let i = _.findIndex(this.itemSelectedList, { id: item.id });
      if (i >= 0) {
        let qty = Number.parseInt(this.itemSelectedList[i].qty);
        if (qty > 1) {
          this.itemSelectedList[i].qty = qty - 1;
        } else {
          this.deletItem(item, i);
        }
      }
    },
    deletItem(item, index) {
      this.confirmDeleteDlg = true;
      this.itemSelectedIndex = {
        index,
        name: item.name,
      };
    },
    confirmDeleteItem() {
      this.itemSelectedList.splice(this.itemSelectedIndex.index, 1);
      this.confirmDeleteDlg = false;
    },
    isValidFormCard(val) {
      // from for() => {show_order_amount}mcardcomponent
      this.enable_submitbtn = val.valid;
      this.show_order_amount = val.amount;
      this.customer_name = val.customer_name;
      // console.log(val)
    },
    changeAmount(val) {
      this.show_order_amount = val;
    },
    submitFormCard() {
      this.$refs.formcardaddress.submit();
    },
  },
  watch: {
    item: {
      handler(newVal, oldVal) {
        let item = newVal.item;
        let i = _.findIndex(this.itemSelectedList, { id: item.id });
        if (i < 0) {
          //not found
          this.itemSelectedList.push({
            id: item.id,
            name: item.name,
            image: item.image,
            subname: item.subname,
            qty: (typeof newVal.initQty == 'undefined' ? 1: Number.parseInt(newVal.initQty)),
            cost: item.cost,
          });
        } else {
          if (typeof newVal.initQty == 'undefined' && this.itemSelectedList[i].qty >= 1) {
            this.itemSelectedList[i].qty += 1;
          }
        }
      },
    },
  },
  beforeDestroy: function() {
    delete this.products;
    delete this.categories;
    delete this.itemSelectedList;
  },
};
</script>

<style></style>